import React from "react";
import Layout from "../comps/reusable/Layout";
import { Link, graphql } from "gatsby";
import Seo from "../comps/reusable/Seo";
import {
  FaqsContainer,
  GetTalentSection,
  Hero,
  HuntingGlobal,
  InfoSection,
} from "../styles/pages/headhunting";
import { Briefcase } from "styled-icons/bootstrap";
import { Location } from "styled-icons/ionicons-outline";
import { Time } from "styled-icons/boxicons-regular";
import huntingHero from "../images/hunting/hunting-hero.png";
import profileImage from "../images/hunting/profiles.png";
import usImage from "../images/hunting/us.png";
import stars from "../images/hunting/stars.png";
import underline from "../images/hunting/underline.png";
import union from "../images/hunting/union.png";
import tempMap from "../images/hunting/temp-map.png";
import getTalent from "../images/hunting/get-talent.png";
import Carousel from "../comps/reusable/Carousel";
import CarouselTestimonials from "../comps/reusable/CarouselTestimonials";
import Faqs from "../comps/reusable/Faqs";
import { useTranslation } from "react-i18next";

const logoOne =
  "https://firebasestorage.googleapis.com/v0/b/lapieza-production.appspot.com/o/resources%2FB2B%2Flogo-1.png?alt=media&token=9c736c96-2da0-4366-89a6-a1d745b0921d";
const logoTwo =
  "https://firebasestorage.googleapis.com/v0/b/lapieza-production.appspot.com/o/resources%2FB2B%2Flogo-2.png?alt=media&token=3f8880c8-1b24-4840-9b9e-6ffff200ac38";
const logoThree =
  "https://firebasestorage.googleapis.com/v0/b/lapieza-production.appspot.com/o/resources%2FB2B%2Flogo-3.png?alt=media&token=d627ac40-ba19-40fa-b507-573ab8fad721";
const logoFour =
  "https://firebasestorage.googleapis.com/v0/b/lapieza-production.appspot.com/o/resources%2FB2B%2Flogo-4.png?alt=media&token=6454d413-318a-44a8-b3dd-99542c8ab241";
const logoFive =
  "https://firebasestorage.googleapis.com/v0/b/lapieza-production.appspot.com/o/resources%2FB2B%2Flogo-5.png?alt=media&token=60b8147c-7ccf-4304-a53b-572992bd6230";
const logoSix =
  "https://firebasestorage.googleapis.com/v0/b/lapieza-production.appspot.com/o/resources%2FB2B%2Flogo-6.png?alt=media&token=89855dad-92fd-4697-a9e2-985694482876";
const logoSeven =
  "https://firebasestorage.googleapis.com/v0/b/lapieza-production.appspot.com/o/resources%2FB2B%2Flogo-7.png?alt=media&token=8d203f8d-d1a7-4c82-960c-014a2b70ba52";
const logoEight =
  "https://firebasestorage.googleapis.com/v0/b/lapieza-production.appspot.com/o/resources%2FB2B%2Flogo-8.png?alt=media&token=2423ff8b-4052-40bf-a425-ec29b18e0f52";
const logoNine =
  "https://firebasestorage.googleapis.com/v0/b/lapieza-production.appspot.com/o/resources%2FB2B%2Flogo-9.png?alt=media&token=f55c48c5-b44a-4ffd-9590-c78dcda3b785";

const Headhunting = () => {
  const { t } = useTranslation(["hunting", "faqs"]);

  const faqs = [
    {
      question: t("question1"),
      answer: t("answer1"),
    },
    {
      question: t("question2"),
      answer: t("answer2"),
    },
    {
      question: t("question3"),
      answer: t("answer3"),
    },
  ];

  return (
    <Layout>
      <Seo
        title="¿Buscando agencias de reclutamiento? Encontramos los perfiles más complejos"
        description="Queremos que cumplas tus objetivos de reclutamiento en tiempo récord. Un sourcing inteligente, eficiente y humano."
        image="https://firebasestorage.googleapis.com/v0/b/lapieza-production.appspot.com/o/resources%2FSEO%2FTAG3.png?alt=media&token=1e0186ca-89d1-4e65-9386-e05e7e7c4ce7"
      />
      <Hero>
        <div className="hero-main main-landing-container-column">
          <article>
            <h1>{t("heroTitle")}</h1>
            <h2>{t("heroSubtitle")}</h2>
            <a
              href="https://ats.lapieza.io/authorize-me"
              target="_blank"
              rel="noopener noreferrer"
            >
              {t("heroButton")}
            </a>
          </article>
          <figure className="hero-image">
            <img src={huntingHero} alt="Ilustración del equipo de LaPieza" />
          </figure>
        </div>
        <div className="hero-info">
          <div className="hero-info-container main-landing-container-row">
            <article>
              <span className="hero-icon-container">
                <Briefcase size={24} color="#EF8227" />
              </span>
              <div>
                <h5>{t("heroItemTitle1")}</h5>
                <p>{t("heroItemDescription1")}</p>
              </div>
            </article>
            <article>
              <span className="hero-icon-container">
                <Location size={24} color="#EF8227" />
              </span>
              <div>
                <h5>{t("heroItemTitle2")}</h5>
                <p>{t("heroItemDescription2")}</p>
              </div>
            </article>
            <article>
              <span className="hero-icon-container">
                <Time size={24} color="#EF8227" />
              </span>
              <div>
                <h5>{t("heroItemTitle3")}</h5>
                <p>{t("heroItemDescription3")}</p>
              </div>
            </article>
          </div>
        </div>
      </Hero>
      <InfoSection>
        <div className="item-section item-section-one  main-landing-container-row">
          <article>
            <h5>{t("infoItemTitle1")}</h5>
            <h3>{t("infoItemSubtitle1")}</h3>
            <p>{t("infoItemDescription1")}</p>
          </article>
          <figure>
            <img src={profileImage} alt="Foto de integrante de LaPieza" />
          </figure>
        </div>
        <div className="item-section item-section-two  main-landing-container-row">
          <figure>
            <img src={usImage} alt="Foto de integrante de LaPieza" />
          </figure>
          <article>
            <h3>{t("infoItemSubtitle2")}</h3>
            <p>{t("infoItemDescription2")}</p>
            <span>{t("infoItemMessage2")}</span>
            <a
              href="https://calendly.com/alexa-villegas"
              target="_blank"
              rel="noopener noreferrer"
            >
              {t("seeMore")}
            </a>
          </article>
        </div>
        <article className="subtitle-info main-landing-container-row">
          <h3>
            <figure className="stars-title">
              <img src={stars} alt="Figuras de strellas" />
            </figure>
            <span>
              +500
              <figure>
                <img src={underline} alt="Figuras de subrayado" />
              </figure>
            </span>
          </h3>
          <p>{t("infoFooterTitle")}</p>
        </article>
        <div className="info-list">
          <Carousel logos={[logoOne, logoTwo, logoThree, logoFour, logoFive]} />
          <Carousel logos={[logoSix, logoSeven, logoEight, logoNine]} />
        </div>
      </InfoSection>
      <HuntingGlobal>
        <div className="map-section">
          <div className="main-landing-container-column">
            <h2 className="main-title">{t("huntingTitle")}</h2>
            <p className="main-description">{t("huntingDescription")}</p>
            <figure className="figure-union">
              <img src={union} alt="Figura de dirección" />
            </figure>
            <figure>
              <img src={tempMap} alt="Figura de dirección" />
            </figure>
          </div>
        </div>
        <div className="testimonials-section">
          <h2>{t("testiomonialsTitle")}</h2>
          <CarouselTestimonials />
        </div>
      </HuntingGlobal>
      <FaqsContainer>
        <Faqs faqs={faqs} />
      </FaqsContainer>
      <GetTalentSection>
        <div className="main-landing-container-row">
          <article>
            <h2>{t("getTalentTitle")}</h2>
            <p>{t("getTalentDescription")}</p>
            <a
              href="https://calendly.com/alexa-villegas"
              target="_blank"
              rel="noopener noreferrer"
            >
              {t("getTalentContactAction")}
            </a>
          </article>
          <figure>
            <img src={getTalent} alt="Ilustración de LaPieza" />
          </figure>
        </div>
      </GetTalentSection>
    </Layout>
  );
};

export default Headhunting;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
